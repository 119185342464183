import { posHttp } from '@/utils/axios'

/**
 * 获取预授权码
 */
export const getPreAuthCodeApi = () => posHttp.get(`/wechat/component/preauthcode`)

/**
 * 获取预授权码
 */
export const saveAuthInfoApi = (brandCode, authCode) => posHttp.post(`/wechat/authorizer/auth/success?brandCode=${brandCode}&authCode=${authCode}`)

/**
 * 生成小程序链接
 * @param {Object } data 参数
 */
export const generateWxProgramLinkApi = (data) => posHttp.post('/wechat/program/url/link/generate', data)

/**
 * 获取品牌绑定的小程序信息
 * @param {String } brandCode 品牌编号
 */
export const getBrandWxProgramApi = (brandCode) => posHttp.get(`/wechat/brand/program?brandCode=${brandCode}`)
